import React, { RefObject } from 'react';
import { Box, Stack, Link, Theme, FormHelperText } from '@mui/material';

import { MessageDto } from '@keyops-hcp/dtos';
import { formatDateStringEditedTooltip } from '../../utils/datetime';
import i18next from '../../languages/i18n.config';
import { extractMentionIds } from '../../utils/discussion';
import { useEditMessage } from '../../hooks/use-edit-message';
import { PrimaryTooltip } from '../PrimaryTooltip';
import { EditRectangle } from '../../icons';
import { MentionInput } from '../MentionInput';
import { SubmitHandler, useForm } from 'react-hook-form';
import ReactPlayer from 'react-player';
import { SecondaryButton } from '../SecondaryButton';
import { PrimaryButton } from '../PrimaryButton';

export const DiscussionMessageEdit = ({
  message: originalMessage,
  setIsEditing,
}: {
  message: MessageDto;
  setIsEditing: (value: boolean) => void;
  playerRef?: RefObject<ReactPlayer | null>;
}) => {
  const { mutate: editMessage } = useEditMessage();
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm<{ message: string }>({
    defaultValues: {
      message: originalMessage.content,
    },
  });

  const handleSubmitEdit: SubmitHandler<{ message: string }> = async (
    messageToSave,
  ) => {
    const mentionedUserIds = extractMentionIds(messageToSave.message);
    editMessage({
      discussionId: originalMessage.discussionId,
      messageId: originalMessage.id,
      newContent: messageToSave.message.trim(),
      newContext: { ...originalMessage.context, mentions: mentionedUserIds },
    });
    setIsEditing(false);
  };

  return (
    <form
      onSubmit={handleSubmit(handleSubmitEdit)}
      style={{
        transition: 'opacity 0.5s ease-in-out', // Smooth form transitions when submitting
      }}
    >
      <Stack spacing={2}>
        <MentionInput
          setValue={setValue}
          control={control}
          showSendButton={false}
        />
        {errors.message && (
          <FormHelperText>{errors.message.message}</FormHelperText>
        )}
        <Box display="flex" justifyContent="flex-end">
          <SecondaryButton
            variant="outlined"
            onClick={() => setIsEditing(false)}
            sx={{
              fontSize: 16,
              textTransform: 'capitalize',
            }}
          >
            {i18next.t('common.cancel')}
          </SecondaryButton>
          <PrimaryButton
            type="submit"
            variant="contained"
            sx={{
              fontSize: 16,
              textTransform: 'capitalize',
              ml: 1,
            }}
          >
            {i18next.t('common.save')}
          </PrimaryButton>
        </Box>
      </Stack>
    </form>
  );
};

export const EditCta = ({
  setIsEditing,
  theme,
  hideCtaText,
}: {
  isEditing: boolean;
  setIsEditing: (value: boolean) => void;
  theme: Theme;
  hideCtaText: boolean;
}) => {
  return (
    <Link
      component="button"
      onClick={() => setIsEditing(true)}
      sx={{
        color: theme.palette.keyops.blue.keyopsBlue,
        fontSize: 12,
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
        gap: 0.5,
      }}
    >
      <PrimaryTooltip
        title={i18next.t('ad_board.discussion.edit_tooltip')}
        hideTooltip={!hideCtaText}
      >
        <span>
          <EditRectangle sx={{ fontSize: 16 }} />
        </span>
      </PrimaryTooltip>
      {hideCtaText ? '' : i18next.t('ad_board.discussion.edit')}
    </Link>
  );
};

export const EditedAtText = ({
  message,
  theme,
}: {
  message: MessageDto;
  theme: Theme;
}) => {
  return (
    <PrimaryTooltip title={formatDateStringEditedTooltip(message.editedAt)}>
      <span
        style={{
          fontSize: 12,
          color: theme.palette.keyops.text.secondary,
          display: 'inline',
        }}
      >
        &nbsp;&nbsp;{i18next.t('ad_board.discussion.edited')}
      </span>
    </PrimaryTooltip>
  );
};
