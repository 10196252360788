import { useMutation } from '@tanstack/react-query';

import {
  CombinedMessageContextDto,
  editMessage,
} from '../api/discussion-messages';
import { queryClient } from '../wrappers';
import { analytics } from '../utils/analytics';

interface BaseMessageParameters {
  discussionId: string;
  messageId: string;
  newContent: string;
  newContext: CombinedMessageContextDto;
}

/**
 * @param {string} discussionId - The id of the current Discussion
 * @param {string} messageId - The id of the current Message
 * @param {string} newContent - The new message content
 * @param {string} newContext - The new message context
 * @returns
 */
export const useEditMessage = () => {
  return useMutation<
    {
      discussionId: string;
      messageId: string;
      newContent: string;
      newContext: CombinedMessageContextDto;
    },
    unknown,
    BaseMessageParameters
  >(
    async ({
      discussionId,
      messageId,
      newContent,
      newContext,
    }): Promise<BaseMessageParameters> => {
      await editMessage(discussionId, messageId, newContent, newContext);
      return { discussionId, messageId, newContent, newContext };
    },
    {
      onSuccess: async (result, parameters) => {
        analytics.track(`AdBoard Edit Discussion`, {
          discussionId: result.discussionId,
          messageId: result.messageId,
          newContent: result.newContent,
          newContext: result.newContext,
        });
        // Refetch the discussions query
        await queryClient.invalidateQueries({
          queryKey: ['getOneDiscussion', parameters.discussionId],
        });
      },
      onError: (error) => {
        console.error(error);
      },
    },
  );
};
