import React, { useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';
import { Box, useTheme } from '@mui/material';
import {
  AdBoardSectionProgressDto,
  AdBoardVideoSectionProgressCombined,
  VideoDto,
} from '@keyops-hcp/dtos';

import { Video } from '../Video';
import { VideoDiscussion } from '../VideoDiscussion';

interface VideoWrapperProperties {
  videoContent: VideoDto;
  currentSectionProgress?: AdBoardSectionProgressDto;
}

export const VideoWrapper = ({
  videoContent,
  currentSectionProgress,
}: VideoWrapperProperties) => {
  const { palette } = useTheme();
  const playerReference = useRef<ReactPlayer | null>(null);

  // Ensure type safety for `currentSectionProgress`
  const videoTime = useMemo(
    () =>
      currentSectionProgress && 'currentTime' in currentSectionProgress
        ? (currentSectionProgress as AdBoardVideoSectionProgressCombined)
            .currentTime
        : 0,
    [currentSectionProgress],
  );

  return (
    <Box display={'flex'} flexDirection={'row'} maxHeight={'500px'} mb={2}>
      <Box
        width={'55%'}
        border={`1px solid ${palette.keyops.blue.lightSteelBlue20}`}
        sx={{
          borderTopLeftRadius: 6,
          borderBottomLeftRadius: 6,
          backgroundColor: palette.keyops.gray.background,
        }}
      >
        <Video
          videoId={videoContent.id}
          videoTime={videoTime}
          ref={playerReference}
        />
      </Box>
      <Box
        width={'45%'}
        border={`1px solid ${palette.keyops.blue.lightSteelBlue20}`}
        display={'flex'}
        flexDirection={'column'}
        sx={{
          borderTopRightRadius: 6,
          borderBottomRightRadius: 6,
        }}
      >
        <VideoDiscussion
          discussionId={videoContent.discussionId}
          playerRef={playerReference}
        />
      </Box>
    </Box>
  );
};
