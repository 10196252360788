/* eslint-disable unicorn/filename-case */
import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const EditRectangle = (properties: SvgIconProps) => (
  <SvgIcon {...properties}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4C5.34315 4 4 5.34315 4 7V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V11C20 10.4477 20.4477 10 21 10C21.5523 10 22 10.4477 22 11V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V7C2 4.23858 4.23858 2 7 2H13C13.5523 2 14 2.44772 14 3C14 3.55228 13.5523 4 13 4H7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1973 4C18.9845 4 18.7803 4.08457 18.6298 4.23509L10.7527 12.1121L10.3744 13.6256L11.8879 13.2473L19.7649 5.37021C19.9154 5.21969 20 5.01553 20 4.80265C20 4.58978 19.9154 4.38562 19.7649 4.23509C19.6144 4.08457 19.4102 4 19.1973 4ZM17.2156 2.82088C17.7412 2.29528 18.454 2 19.1973 2C19.9407 2 20.6535 2.29528 21.1791 2.82088C21.7047 3.34648 22 4.05934 22 4.80265C22 5.54596 21.7047 6.25883 21.1791 6.78443L13.1062 14.8573C12.9781 14.9855 12.8175 15.0764 12.6417 15.1204L9.24254 15.9701C8.90176 16.0553 8.54127 15.9555 8.29289 15.7071C8.04451 15.4587 7.94466 15.0982 8.02986 14.7575L8.87964 11.3583C8.92359 11.1825 9.01451 11.0219 9.14267 10.8938L17.2156 2.82088Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2929 5.29289C15.6834 4.90237 16.3166 4.90237 16.7071 5.29289L18.7071 7.29289C19.0976 7.68342 19.0976 8.31658 18.7071 8.70711C18.3166 9.09763 17.6834 9.09763 17.2929 8.70711L15.2929 6.70711C14.9024 6.31658 14.9024 5.68342 15.2929 5.29289Z"
        fill="currentColor"
      />
    </svg>
  </SvgIcon>
);
