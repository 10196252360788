import React, { RefObject, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { MessageDto } from '@keyops-hcp/dtos';
import { DiscussionMessage } from '../DiscussionMessage';
import { fetchUrlHashData, scrollToMessageId } from '../../utils/discussion';
import ReactPlayer from 'react-player';

interface DiscussionChatMessagesProperties {
  isThread?: boolean;
  pageDisplay?: boolean;
  messages: MessageDto[];
  shouldScrollToBottom?: boolean; // Add this prop to control scrolling
  hideCtaText?: boolean;
  isThreadHidden?: boolean;
  onReplyToggle?: () => void;
  playerRef?: RefObject<ReactPlayer | null>;
}

export const DiscussionChatMessages = ({
  isThread,
  pageDisplay,
  messages,
  shouldScrollToBottom,
  hideCtaText = false,
  isThreadHidden = false,
  onReplyToggle,
  playerRef,
}: DiscussionChatMessagesProperties): React.JSX.Element => {
  const location = useLocation();
  const boxReference = useRef<HTMLDivElement>(null);

  // Scroll to the bottom only when shouldScrollToBottom is true
  useEffect(() => {
    if (boxReference.current && shouldScrollToBottom && !isThread) {
      boxReference.current.scrollTop = boxReference.current.scrollHeight;
    }
  }, [shouldScrollToBottom]);

  const { msgId } = fetchUrlHashData();

  useEffect(() => {
    if (msgId) {
      scrollToMessageId(msgId);
    }
  }, [msgId, location]);

  return (
    <Box
      display="flex"
      flexDirection={pageDisplay ? 'column' : 'column-reverse'}
      gap={pageDisplay ? 2 : 0}
      alignContent="flex-start"
      ref={boxReference}
      flexGrow={1}
      overflow="auto"
      p={isThread ? '16px 0 0 0' : '16px 0'}
    >
      {messages?.map((message) => (
        <DiscussionMessage
          key={message.id}
          highlighted={msgId === message.id}
          message={message}
          onReplyToggle={onReplyToggle}
          hideCtaText={hideCtaText}
          isThreadHidden={isThreadHidden}
          playerRef={playerRef}
        />
      ))}
    </Box>
  );
};
