import React, { RefObject } from 'react';
import { Box, useTheme } from '@mui/material';
import ReactPlayer from 'react-player';

import { useOneDiscussion } from '../../hooks/useDiscussion';
import { Loader } from '../Loader';
import i18next from '../../languages/i18n.config';
import { DiscussionMessageForm } from '../DiscussionMessageForm';
import { DiscussionChatMessages } from '../DiscussionChatMessages';
import { KeyopsHeader2 } from '../Headers';

interface VideoDiscussionProperties {
  discussionId: string;
  playerRef: RefObject<ReactPlayer | null>;
}

export const VideoDiscussion = ({
  discussionId,
  playerRef,
}: VideoDiscussionProperties) => {
  const {
    data: discussion,
    isLoading,
    isError,
  } = useOneDiscussion(discussionId);

  const { palette } = useTheme();

  if (isLoading) return <Loader />;
  if (isError) return <div>{i18next.t('common.error')}</div>;

  return (
    <>
      <Box flexGrow={1} overflow={'auto'} p={2}>
        {discussion?.messages?.length === 0 ? (
          <KeyopsHeader2
            fontWeight={400}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              py: 0,
              px: 2,
              height: '100%',
            }}
          >
            {i18next.t('video.comments.no_comments_message')}
          </KeyopsHeader2>
        ) : (
          <DiscussionChatMessages
            pageDisplay={true}
            messages={discussion?.messages}
            playerRef={playerRef}
          />
        )}
      </Box>
      <Box
        borderTop={`1px solid ${palette.keyops.blue.lightSteelBlue20}`}
        p={2}
      >
        <DiscussionMessageForm
          discussionId={discussionId}
          playerRef={playerRef}
        />
      </Box>
    </>
  );
};
