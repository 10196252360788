import 'reflect-metadata';

import {
  IsArray,
  IsBoolean,
  IsEmail,
  IsNumber,
  IsOptional,
  IsString,
  IsNotEmpty,
  ValidateNested,
  IsUUID,
  IsDate,
  IsIn,
} from 'class-validator';
import { Type } from 'class-transformer';
import { UUID } from 'node:crypto';
import {
  Countries,
  Country,
  HcpType,
  HcpTypes,
  Genders,
  Gender,
  Ethnicity,
  Ethnicities,
  Language,
  StateOrProvince,
  Specialty,
  SubSpecialty,
  PhysicianPracticeSetting,
  PharmacistPracticeSpecialty,
  PharmacistRetailChain,
  PharmacistType,
  PharmacistPracticeSetting,
  NurseEmploymentStatus,
  NurseOccupations,
  NurseOccupation,
  NursePracticeSettings,
  NursePracticeSetting,
  NursePracticeSettingDetail,
  NurseSpecialty,
  NurseSpecialties,
  NursePracticeSettingDetails,
  NurseEmploymentStatuses,
} from '@keyops-hcp/constants';

export class PhysicianDetails {
  @IsArray()
  @IsString({ each: true })
  specialty!: Specialty[];

  @IsArray()
  @IsString({ each: true })
  subSpecialty!: SubSpecialty[];

  @IsString()
  licenseNumber!: string;

  @IsString()
  practiceLicenseYear!: string;

  @IsString()
  practiceSetting!: PhysicianPracticeSetting;

  @IsString()
  workEmail!: string;

  @IsOptional()
  @IsBoolean()
  bypassWorkEmailVerification?: boolean;

  @IsOptional()
  @IsString()
  otherDetails?: string;
}

export class PharmacistDetails {
  @IsString()
  licenseNumber!: string;

  @IsString()
  practiceLicenseYear!: string;

  @IsString()
  practiceSetting!: PharmacistPracticeSetting;

  @IsOptional()
  @IsString()
  otherPracticeSetting?: string;

  @IsOptional()
  @IsString()
  practiceSpecialty?: PharmacistPracticeSpecialty;

  @IsOptional()
  @IsString()
  otherPracticeSpecialty?: string;

  @IsOptional()
  @IsString()
  retailChain?: PharmacistRetailChain;

  @IsOptional()
  @IsString()
  otherRetailChain?: string;

  @IsString()
  pharmacistType!: PharmacistType;

  @IsOptional()
  @IsString()
  otherDetails?: string;
}

export class NurseDetails {
  @IsIn(NurseOccupations)
  occupation?: NurseOccupation;

  @IsOptional()
  @IsString()
  otherOccupation?: string;

  @IsIn(NursePracticeSettings)
  practiceSetting?: NursePracticeSetting;

  @IsOptional()
  @IsString()
  otherPracticeSetting?: string;

  @IsOptional()
  @IsArray()
  @IsIn(NurseSpecialties, { each: true })
  specialty?: NurseSpecialty[];

  @IsOptional()
  @IsString()
  otherSpecialty?: string;

  @IsOptional()
  @IsIn(NursePracticeSettingDetails)
  practiceSettingDetail?: NursePracticeSettingDetail;

  @IsOptional()
  @IsString()
  otherPracticeSettingDetail?: string;

  @IsIn(NurseEmploymentStatuses)
  employmentStatus: NurseEmploymentStatus;

  @IsString()
  licenseNumber!: string;

  @IsString()
  practiceLicenseYear!: string;

  @IsString()
  workEmail!: string;

  @IsOptional()
  @IsBoolean()
  bypassWorkEmailVerification?: boolean;

  @IsOptional()
  @IsString()
  otherDetails?: string;
}

export class GoogleOnboardingSaveState<T extends HcpType> {
  @IsString()
  cityOfPractice!: string;

  @IsIn(Countries)
  country!: Country;

  @Type(() => Date)
  @IsDate()
  dateOfBirth!: Date;

  @IsEmail()
  email!: string;

  @IsArray()
  @IsString({ each: true })
  engagementPreference!: string[];

  @IsArray()
  @IsIn(Ethnicities, { each: true })
  ethnicity!: Ethnicity[];

  @IsString()
  firstName!: string;

  @IsIn(Genders)
  gender!: Gender;

  @IsBoolean()
  involvementCommittee!: boolean;

  @IsString()
  lastName!: string;

  @IsOptional()
  @IsString()
  mobileNumber?: string;

  @IsOptional()
  @IsString()
  password?: string;

  @IsBoolean()
  privacyPolicy!: boolean;

  @IsString()
  provinceOfPractice!: StateOrProvince;

  @IsBoolean()
  smsNotifications!: boolean;

  @IsString()
  languagePreference!: Language;

  @IsBoolean()
  termsOfService!: boolean;

  @IsNumber()
  yearOfBirth!: number;

  @IsOptional()
  @IsUUID()
  linkId?: UUID;

  @IsOptional()
  @IsUUID()
  referralId?: UUID;

  @IsOptional()
  @IsUUID()
  adBoardInviteId?: UUID;

  @IsNotEmpty()
  @IsIn(HcpTypes)
  hcpType!: T;

  @ValidateNested()
  @Type((options) => {
    if (options && options.object.hcpType === 'physician') {
      return PhysicianDetails;
    } else if (options && options.object.hcpType === 'nurse') {
      return NurseDetails;
    } else {
      return PharmacistDetails; // Default to PharmacistDetails if not physician
    }
  })
  hcpProfile!: T extends 'physician'
    ? PhysicianDetails
    : T extends 'nurse'
      ? NurseDetails
      : PharmacistDetails;
}

export class OnboardingSaveState extends GoogleOnboardingSaveState<HcpType> {
  @IsString()
  declare password: string;
}

export type HcpOnboarding =
  | GoogleOnboardingSaveState<'physician'>
  | GoogleOnboardingSaveState<'pharmacist'>
  | GoogleOnboardingSaveState<'nurse'>;

export class OnboardingSaveEvent {
  @ValidateNested()
  @Type(() => OnboardingSaveState)
  state!: OnboardingSaveState;
}

export class GoogleOnboardingSaveEvent {
  @IsString()
  googleJWT!: string;

  @ValidateNested()
  @Type(() => GoogleOnboardingSaveState)
  state!: GoogleOnboardingSaveState<HcpType>;
}

export class IQVIAOnboardingSaveEvent {
  @IsString()
  iqviaJWT!: string;

  @ValidateNested()
  @Type(() => GoogleOnboardingSaveState)
  state!: GoogleOnboardingSaveState<HcpType>;
}
