import i18n from '../languages/i18n.config';

const intervalKeyMappings = new Map([
  [31_536_000, 'years'], //365 days
  [2_592_000, 'months'], //30 days
  [86_400, 'days'],
  [3600, 'hours'],
  [60, 'minutes'],
]);
export const getTimeSince = (date: Date): string => {
  const secondsDiff = Math.floor(
    (Date.now() - new Date(date).getTime()) / 1000,
  );

  for (const [key, value] of [...intervalKeyMappings]) {
    if (secondsDiff >= key) {
      return i18n.t('since.' + value, { count: Math.floor(secondsDiff / key) });
    }
  }
  //fall back to seconds
  return i18n.t('since.seconds', { count: secondsDiff });
};

export const getVideoTime = (seconds: number): string => {
  const dateString = new Date(seconds * 1000).toISOString();
  if (seconds < 600) {
    //if it's less than 10 mins, we drop the hour part and first minute, and only show M:SS
    return dateString.slice(15, 19);
  }
  if (seconds < 3600) {
    //if it's less than an hour, we drop the hour part, and only show MM:SS
    return dateString.slice(14, 19);
  }
  if (seconds < 36_000) {
    //if it's less than 10 hours, we drop the leading hour digit, and only show H:MM:SS
    return dateString.slice(12, 19);
  }
  //Otherwise show HH:MM:SS
  return dateString.slice(11, 19);
};

export const getFormattedDatesForRange = (
  language: string,
  startDateString?: string,
  endDateString?: string,
): { formattedStartDate?: string; formattedEndDate?: string } => {
  if (!startDateString || !endDateString) {
    return {};
  }
  const startDate = new Date(startDateString);
  const endDate = new Date(endDateString);

  //only include year if start date and end date are different years
  const startDateYearFormat =
    startDate.getFullYear() === endDate.getFullYear() ? undefined : 'numeric';

  const formattedStartDate = startDate.toLocaleDateString(language, {
    month: 'short',
    day: 'numeric',
    year: startDateYearFormat,
  });
  const formattedEndDate = endDate.toLocaleDateString(language, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return { formattedStartDate, formattedEndDate };
};

const getDaySuffix = (day: number) => {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1: {
      return 'st';
    }
    case 2: {
      return 'nd';
    }
    case 3: {
      return 'rd';
    }
    default: {
      return 'th';
    }
  }
};

export const formatDateWithSuffix = (date: string) => {
  const dateObject = new Date(date);
  // Check if the date is invalid
  if (Number.isNaN(dateObject.getTime())) {
    return 'Invalid Date';
  }
  const formattedDate = dateObject.toLocaleString('en-US', {
    month: 'long',
    day: 'numeric',
  });
  const day = dateObject.getDate();

  const daySuffix = getDaySuffix(day);

  return `${formattedDate}${daySuffix}`;
};

export const formatSecondsDuration = (seconds: number): string => {
  if (seconds < 0) {
    throw new Error('Seconds cannot be negative.');
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 0) {
    if (minutes > 0) {
      return `${hours} h ${minutes} min`;
    }
    return `${hours} h`;
  } else if (minutes > 0) {
    if (remainingSeconds > 0) {
      return `${minutes} min ${remainingSeconds} sec`;
    }
    return `${minutes} min`;
  }
  return `${remainingSeconds} sec`;
};

// Used in Chat to display the time when a message was sent
export const getMinutesAgoFromString = (sentDateString: string): string => {
  const sentDate = new Date(sentDateString);
  const now = new Date();
  const diffMilliseconds = now.getTime() - sentDate.getTime();

  // First, calculate total difference in days, hours, and minutes
  const diffDays = Math.floor(diffMilliseconds / (3_600_000 * 24)); // total days
  const diffMinutes = Math.floor((diffMilliseconds % 3_600_000) / 60_000); // remainder in minutes
  const diffHours = Math.floor(diffMilliseconds / 3_600_000); // total hours
  const diffWeeks = Math.floor(diffDays / 7);

  // Calculate the remaining hours after accounting for full days
  const remainingHours = diffHours % 24;

  if (diffMinutes === 0 && remainingHours === 0 && diffDays === 0) {
    return `Just now.`;
  }

  // Check if the message was sent the same day
  if (diffDays < 1) {
    if (sentDate.toDateString() === now.toDateString()) {
      const hours = sentDate.getHours();
      const minutes = sentDate.getMinutes();
      const formattedTime =
        (hours % 12 || 12) +
        ':' +
        (minutes < 10 ? '0' : '') +
        minutes +
        ' ' +
        (hours < 12 ? 'AM.' : 'PM.');
      return formattedTime;
    }
    return `${diffHours}h ago.`;
  }
  // Check if the message was sent more than a day ago but less than a week ago
  else if (diffDays < 7) {
    return `${diffDays}d ago.`;
  }
  // If the message was sent a week or more ago
  return `${diffWeeks}w ago.`;
};

export const dateFormatter = (timestamp?: string): string | null => {
  if (!timestamp) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }
  return new Date(timestamp).toLocaleString(['en-CA'], {
    formatMatcher: 'best fit',
    dateStyle: 'medium',
  });
};

export const timeFormatter = (timestamp?: string): string | null => {
  if (!timestamp) {
    // eslint-disable-next-line unicorn/no-null
    return null;
  }

  return (
    new Date(timestamp)
      .toLocaleTimeString('en-CA', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true, // Ensures AM/PM format
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .replaceAll(/\.\s?/g, '')
  );
};

export const formatDate = (date: string) => {
  const dateObject = new Date(date);

  // Check if the date is invalid
  if (Number.isNaN(dateObject.getTime())) {
    return 'Invalid Date';
  }

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  // Remove time portion for accurate comparison
  const isToday = dateObject.toDateString() === today.toDateString();
  const isYesterday = dateObject.toDateString() === yesterday.toDateString();

  if (isToday) return 'Today';
  if (isYesterday) return 'Yesterday';

  return dateObject.toLocaleString('en-CA', { month: 'short', day: 'numeric' });
};

// Formats a date string to be similar to "Mar 11 at 12:34 PM"
export function formatDateStringEditedTooltip(dateString: string): string {
  const date = new Date(dateString);
  const now = new Date();
  const isDifferentYear = date.getFullYear() !== now.getFullYear();

  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
    day: 'numeric', // Removes leading 0 from day
    year: isDifferentYear ? 'numeric' : undefined, // Include year if different
    hour: 'numeric', // Removes leading 0 from hour
    minute: '2-digit',
    hour12: true,
  };

  let formattedDate = date.toLocaleString('en-US', options);

  // Ensure correct placement of 'at'
  if (isDifferentYear) {
    const parts = formattedDate.split(', ');
    formattedDate = `${parts[0]}, ${parts[1]} at ${parts[2]}`;
  } else {
    formattedDate = formattedDate.replace(',', ' at');
  }

  return formattedDate;
}
