import React from 'react';
import { Control, Controller, UseFormSetValue } from 'react-hook-form';
import { FiLoader } from 'react-icons/fi';

import { Box, Button, keyframes, OutlinedInput, useTheme } from '@mui/material';

import { BaseTagData } from '@yaireo/tagify';
import '@yaireo/tagify/dist/tagify.css';

import i18next from '../../languages/i18n.config';
import { getParticipantsUserListForMentions } from '../../utils/kolab/attendees.utils';
import { useAdBoardContext } from '../../context';
import { Loader } from '../Loader';
import { useOutlinedInputStyles, useSubmitButtonStyles } from './styles';
import { useTagifyMentions } from '../../hooks/useTagifyMentions';
import { UserDto } from '@keyops-hcp/dtos';

interface MentionInputProperties {
  setValue: UseFormSetValue<{
    message: string;
  }>;
  control: Control<
    {
      message: string;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >;
  userToTag?: Partial<UserDto>;
  showSendButton?: boolean;
}

export interface CustomTagData extends BaseTagData {
  id: string;
  value: string;
}

export const MentionInput = ({
  setValue,
  control,
  userToTag,
  showSendButton = true,
}: MentionInputProperties) => {
  const theme = useTheme();
  const mentionInputStyle = useOutlinedInputStyles(theme);
  const buttonStyle = useSubmitButtonStyles(theme);

  const {
    allParticipantsData,
    isLoading: isContextLoading,
    isError: isContextError,
    userId,
  } = useAdBoardContext();

  const mentionnableUsers = getParticipantsUserListForMentions(
    allParticipantsData,
    userId,
  );

  const mentionableAttendees: CustomTagData[] = mentionnableUsers;

  const { initializeTagify } = useTagifyMentions({
    mentionableAttendees,
    onChange: (newValue: string) => setValue('message', newValue),
  });

  if (isContextError) return <>Something went wrong</>;
  if (isContextLoading) return <Loader />;

  // Define the keyframes for the spin animation
  const spin = keyframes`
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    `;

  const defaultValue = userToTag
    ? `[[{"id":"${userToTag.id}","value":"${userToTag.firstName} ${userToTag.lastName}","prefix":"@"}]] `
    : '';

  return (
    <Controller
      name="message"
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, ref } }) => {
        return (
          <OutlinedInput
            id="discussion-message-message-input"
            aria-describedby="discussion-message-message-input"
            inputRef={(inputReference) => {
              // NOTE: override templates instead of style only?
              initializeTagify(inputReference);
              ref(inputReference); // Pass MUI ref
            }}
            placeholder={i18next.t('forms.helperMessage')}
            value={value} // Bind value to react-hook-form
            onChange={(error) => {
              // Ensure value updates correctly if Tagify doesn't catch it
              onChange(error.target.value);
              setValue('message', error.target.value);
            }}
            fullWidth
            multiline
            sx={mentionInputStyle}
            endAdornment={
              showSendButton && (
                <Button
                  type="submit"
                  variant="outlined"
                  sx={buttonStyle}
                  endIcon={
                    isContextLoading && (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          animation: `${spin} 3s linear infinite`, // Apply the spin animation
                        }}
                      >
                        <FiLoader />
                      </Box>
                    )
                  }
                >
                  {i18next.t('common.send')}
                </Button>
              )
            }
          />
        );
      }}
    />
  );
};
